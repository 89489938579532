<template>
    <div style="padding-top: 8em;">
        <section class="intro clearfix header_service">
            <div class="container" style="max-width:65em; min-height:20em">
                <div class="row">
                <div class="overlay-green"></div>
                    <v-col class="col-md-8 intro-info text-left align-self-center" md="8" style="z-index: 1;">
                        <h1 class="text-uppercase bold text-white mb-4 ls-1 w-75">Pendirian CV</h1>
                        <p class="mb-5 text-white h5">Legalpedia siap melayani Jasa Pembuatan CV sesuai kebutuhan Anda. <br> <br>
                            CV adalah salah satu bentuk badan usaha yang dibentuk oleh dua orang atau lebih yang kemudian mempercayakan modal yang dimiliki kepada dua orang atau lebih. Tujuannya agar tercapainya cita-cita bersama dengan tingkat keterlibatan masing-masing anggotanya berbeda.
                        </p>
                    </v-col>
                    <v-col class="col-md-4" md="4" style="z-index: 1;">
                        <div class="card text-darkgreen">
                            <div class="card-header pt-5 pb-5 border-0">
                                <span>Mulai dari</span>
                                <p class="mt-2 text-center mb-0 pb-0"><s>2.800.000</s></p>
                                <h3 class="text-center mb-0">
                                    IDR 2.000.000
                                </h3>
                            </div>
                            <div class="card-body">
                                <v-btn block small color="primary" class="p-4">Dapatkan Sekarang</v-btn>
                            </div>
                        </div>
                    </v-col>
                </div>
            </div>
        </section>
        <section>
            <v-container class="mt-2">
                <v-row>
                    <v-col cols="12" class="mb-0 mt-0">
                        <v-breadcrumbs :items="breadcumbs" class="mb-1 pb-1 breadcrumb">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item
                                    :to="item.href"
                                    class="text-subtitle-2 crumb-item "
                                    :disabled="item.disabled"
                                    exact
                                >
                                    {{ item.text }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section class="mt-8 mb-8" id="start">
            <v-container class="container">
                <v-row align="center" justify="center">
                    <div class="col-md-4 mb-5" v-for="(detail, index) in services" :key="index">
                        <div class="card sub-service-card">
                            <div class="card-body">
                                <img :src="detail.image" class="center mb-3 img-fluid" style="height: 180px;" v-if="detail.image">
                                <img src="@/assets/pt.png" class="center mb-3 img-fluid" style="height: 180px;" v-else>
                                <a href="">
                                </a>
                                <a href="" class="text-center">
                                    <h5>{{detail.title}}</h5>
                                </a>
                                <p class="mb-1">Bonus :</p>
                                <p class="mb-0 pb-0 mt-1" v-for="(syarat, index) in detail.service_item_syarats" :key="index">
                                    <v-icon color="primary">{{syarat.icon.trim().substr(0,3) == 'mdi' ? syarat.icon : 'mdi-' + syarat.icon}}</v-icon> {{syarat.name}}
                                </p>

                                <div class="w-100" style="height:180px">
                                    <p class="mb-1">Fasilitas :</p>
                                    <p class="mb-0 pb-0 mt-1" v-for="(syarat, index) in detail.service_item_facilities" :key="index">
                                        <v-icon color="primary">{{syarat.icon.trim().substr(0,3) == 'mdi' ? syarat.icon : 'mdi-' + syarat.icon}}</v-icon> {{syarat.name}}
                                    </p>
                                </div>
                            </div>
                            <div class="card-footer border-0 d-flex jutify-content-between">
                                <div class="price-tag w-75">
                                    <span class="small"><s>{{detail.discount_price }}</s></span>
                                    <p class="bold mb-0 mt-0">IDR {{detail.price }}</p>
                                </div>
                                <div class="w-25">
                                    <a :href="detail ? detail.url_link_wa : 'https://api.whatsapp.com/send?phone=+6282120938886&text=Hi%20LegalPedia,%20Saya%20ingin%20konsultasi%20.%20Mohon%20Dibantu'" target="_blank" class="button btn btn-danger mt-1">
                                        <v-icon color="#fff" small>
                                            mdi-arrow-right
                                        </v-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-4 mb-5">
                        <div class="card sub-service-card">
                            <div class="card-body">
                                <img src="@/assets/ptcomplete.png" class="center mb-3 img-fluid">
                                <a href="">
                                </a>
                                <a href="" class="text-center">
                                    <h5>CV Complete</h5>
                                </a>
                                <p class="mb-1">Bonus :</p>
                                <p class="mb-0 pb-0 mt-1">
                                    <v-icon color="primary">mdi-check</v-icon> Free Konsultasi
                                </p>
                                <p class="mb-0 pb-0 pt-0 mt-0">
                                    <v-icon color="primary">mdi-check</v-icon> Pembuatan NPWP Pribadi
                                </p>

                                <div class="w-100" style="height:180px">
                                    <p class="mb-1">Fasilitas :</p>
                                    <p class="mb-0 pb-0 mt-1">
                                        <v-icon color="primary">mdi-magnify</v-icon> Validasi Nama Perusahaan
                                    </p>
                                    <p class="mb-0 pb-0 pt-0 mt-1">
                                        <v-icon color="primary">mdi-book</v-icon> Akta Pendirian
                                    </p>
                                    <p class="mb-0 pb-0 pt-0 mt-1">
                                        <v-icon color="primary">mdi-certificate</v-icon> SK Kemenkumham
                                    </p>
                                    <p class="mb-0 pb-0 pt-0 mt-1">
                                        <v-icon color="primary">mdi-license</v-icon> NPWP & SKT
                                    </p>
                                    <p class="mb-0 pb-0 pt-0 mt-1">
                                        <v-icon color="primary">mdi-file-multiple</v-icon> NIB & Sertifikat Standard (low risk)
                                    </p>
                                </div>
                            </div>
                            <div class="card-footer border-0 d-flex jutify-content-between">
                                <div class="price-tag w-75">
                                    <span class="small"><s>3.500.000</s></span>
                                    <p class="bold mb-0 mt-0">IDR 2.800.000</p>
                                </div>
                                <div class="w-25">
                                    <a href="" class="button btn btn-danger mt-1">
                                        <v-icon color="#fff" small>
                                            mdi-arrow-right
                                        </v-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </v-row>
            </v-container>
        </section>
        <section class="mt-12">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h3 class="font-open-sans text-dark mb-4 bold">
                            Pertanyaan Umum Seputar Pendirian CV
                        </h3>
                    </v-col>
                    <v-col cols="12">
                        <v-expansion-panels
                        v-model="panel"
                        multiple
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>Apa saja dokumen persyaratan untuk mendirikan CV?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                
                                    Para pendiri dan pengurus perusahaan:
                                    <ol class="list-group list-group-numbered">
                                        <li>KTP (e-KTP)</li>
                                        <li>Kartu Keluarga</li>
                                        <li>NPWP
                                            <br>Note:
                                            <ul class="list-group list-group-numbered">
                                                <li>Mohon dipastikan alamat yg tercantum pada KTP dan NPWP sama dan valid</li>
                                                <li>NPWP para pendiri dan pengurus sudah menggunakan format terupdate (terdapat NIK pada NPWP)</li>
                                                <li>Tidak memiliki laporan pajak terhutang</li>
                                            </ul>
                                        </li>
                                    </ol>
                            
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Apakah saya bisa menggunakan rumah tempat tinggal sebagai alamat sekaligus kantor saya? </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                Untuk wilayah DKI Jakarta, alamat yang digunakan CV harus sesuai dengan ketentuan zonasi yang ada dalam Perda DKI Jakarta No. 1/2014. Di aturan tersebut, domisili CV tidak diperkenankan berada di kawasan pemukiman. Sedangkan untuk daerah lain, pastikan terlebih dahulu melalui DPMPTSP setempat mengenai bisa atau tidaknya menggunakan alamat rumah sebagai domisili CV
                                             
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Apa saja yang harus saya perhatikan dalam proses pendirian CV?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                Salah satu hal yang paling penting dalam proses pendirian CV kamu wajib memperhatikan kode Klasifikasi Baku Lapangan Usaha Indonesia (KBLI) yang digunakan pada   maksud dan tujuan yang ada dalam akta pendirian CV. Hal ini dikarenakan Izin usaha yang dibutuhkan suatu perusahaan tergantung dari kode KBLI yang digunakan. Oleh karena itu, salah satu dampak yang muncul ketika kamu tidak menggunakan kode KBLI yang tepat adalah kamu akan diarahkan ke izin usaha yang sebenarnya tidak sesuai dengan kebutuhan perusahaan
              
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <contact-us></contact-us>
    </div>
</template>
<script>
import { mapState } from 'vuex'

import ContactUs from "@/components/layout_front/ContactUs.vue"
import HeaderService from "@/components/layout_front/HeaderService.vue"
import {service_item_api} from "@/backend-api/service_item_api"

export default {
    components: {
        ContactUs, HeaderService
    },
    data: () => ({
        data : '',
        panel:[0,1,2,3],
        breadcumbs: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Pendirian CV',
                disabled: true,
            }
        ],
        services: []
    }),
    methods:{
        async fetchData(){
            var respData = await service_item_api.front(`?service_id=5`, null, false, false, false)
            if (respData.status === 200) {
                this.services = respData.data.data
            } 
        },
    },
    async mounted() { 
        await this.fetchData()
    },
    watch: {
    }
}
</script>

<style scoped>

</style>